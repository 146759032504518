import React from 'react'
import '../styles/index.css'

const Error404 = () => {
  return (
    <main>
      <h1 className='h-screen flex justify-center items-center text-2xl'>404 Not found</h1>
    </main>
  )
}

export default Error404
